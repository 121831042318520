<template>
    <div class="info">
        <div class="width">
            <swiper ref="mySwiper" :options="swiperOptions" @slideChange="slidemoved($event)">
                <swiper-slide class="slide">
                    <div>
                        <div class="illu">
                            <img src="https://app.ikotunconnect.com/assets/connect.svg"/>
                        </div>
                        <div class="title">Ikotun Connect</div>
                        <div class="text">
                            Your gateway to vibrant connections and community bonding. Share, connect, and explore 
                            with like-minded people.
                        </div>
                    </div>
                </swiper-slide>
                <swiper-slide class="slide">
                    <div>
                        <div class="illu">
                            <img src="https://app.ikotunconnect.com/assets/chat.svg"/>
                        </div>
                        <div class="title">Connect and Chat</div>
                        <div class="text">
                            Chat with friends, family, and even AI, making every conversation a unique 
                            experience. Welcome to your social hub.
                        </div>
                    </div>
                </swiper-slide>
                <swiper-slide class="slide">
                    <div>
                        <div class="illu">
                            <img src="https://app.ikotunconnect.com/assets/shopping.svg"/>
                        </div>
                        <div class="title">Seamless Shopping</div>
                        <div class="text">
                            Enjoy a seamless and secure shopping experience from selection to checkout
                            and uncover a world of e-commerce excellence.
                        </div>
                    </div>
                </swiper-slide>
                <swiper-slide class="slide">
                    <div>
                        <div class="illu">
                            <img src="https://app.ikotunconnect.com/assets/vote.svg"/>
                        </div>
                        <div class="title">Digital Democracy</div>
                        <div class="text">
                            Participate in polls, discussions, elections, and decision-making processes 
                            that shape the future of Ikotun Connect.
                        </div>
                    </div>
                </swiper-slide>
            </swiper>
            <div class="page">
                <div class="c" :class="[c == 0 ? 'a' : '']" @click="move(0)"></div>
                <div class="c" :class="[c == 1 ? 'a' : '']" @click="move(1)"></div>
                <div class="c" :class="[c == 2 ? 'a' : '']" @click="move(2)"></div>
                <div class="c" :class="[c == 3 ? 'a' : '']" @click="move(3)"></div>
            </div>
        </div>

        <router-link to="/membership">
            <div class="btn">Get Started</div>
        </router-link>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
export default {
    components: {
        Swiper,
        SwiperSlide
    },
    computed: {
        swiper() {
            return this.$refs.mySwiper.$swiper
        }
    },
    data() {
        return {
            
            c: 0,
            swiperOptions: {
                // resistanceRatio: 0
            }
        }
    },
    methods: {
        slidemoved() {
            this.c = this.swiper.activeIndex
        },
        move(to) {
            this.c = to
            this.swiper.slideTo(to)
        }
    }
}
</script>

<style scoped>
    .info {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        background-color: white;

        /* color: #793484; */
    }
    .width {
        width: 100%;
    }
    .slide {
        width: 100%;
    }

    .illu {
        text-align: center;
    }
    .illu img {
        height: 200px;
    }
    .title {
        font-weight: 800;
        font-size: 20px;
        text-align: center;
        margin-top: 20px;
        color: var(--main);
    }
    .text {
        color: grey;
        padding: 20px;
        text-align: center;
        font-size: 14px;
        line-height: 22px;
        /* font-weight: 500; */
    }

    .page {
        display: flex;
        gap: 10px;
        justify-content: center;
    }
    .c {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        border: 1px solid var(--main);
        cursor: pointer;
        transition: .3s;
    }
    .a {
        background-color: var(--main);
    }
    .btn {
        position: absolute;
        bottom: 20px;
        width: calc(100% - 40px);
        box-sizing: border-box;
        left: 20px;
        background-color: var(--main);
        background: linear-gradient(to right, var(--main), tomato);
        padding: 10px;
        text-align: center;
        color: white;
        font-weight: 500;
        border-radius: 5px;
        cursor: pointer;
    }
</style>